import React, { useRef, useState } from "react"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

import Modal from "./Modal"

function BackgroundSection({ img, styleClass, title, description, children }) {
  // (GF API Call) create a ref with null as initial value

  // const focus = () => {
  //   testRef.current.focus()
  //   console.log(testRef.current)
  // }
  const [showModal, setShowModal] = useState(false)
  const openModal = () => {
    setShowModal(prev => !prev)
    // testRef.current.focus()
  }
  const bgImage = convertToBgImage(img)
  const instance = useRef(null)

  return (
    <BackgroundImage
      Tag="section"
      className={styleClass}
      {...bgImage}
      preserveStackingContext={true}
      style={
        {
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "center center",
          // backgroundAttachment: "fixed",
        }
      }
    >
      <div className="col mx-auto d-flex hero-container">
        <div className="col-11 col-md-6 my-3 d-flex mx-auto ">
          {/* title */}
          <div className="mx-auto hero-main-text">
            <div className="hero-main-text-padding">
              <h1 className="title text-uppercase text-center">{title}</h1>
              <p className="text-center text-uppercase">{description}</p>
            </div>

            <div className="hero-buttons">
              <button
                // id="onlineOrderBtn" //button animation
                className="btn btn-brand text-uppercase "
              >
                <span onClick={openModal}>Order Now</span>
              </button>
            </div>
            <div ref={instance}>
              <Modal
                // ref={string}
                show={showModal}
                setShowModal={setShowModal}
              ></Modal>
            </div>
          </div>
        </div>
      </div>
      {children}
    </BackgroundImage>
  )
}

BackgroundSection.defaultProps = {
  title: "default title",
  styleClass: "default-background",
}
export default BackgroundSection
