import React from "react"

export default function Title({ title }) {
  return (
    <div className="row">
      <div className="col text-center">
        <h1 className="display-3 text-uppercase">{title}</h1>
      </div>
    </div>
  )
}
